import React from "react";
import icarus from "./assets/icarusTransparent.png";

const Icarus = () => {
  return (
    <div className="flex flex-col  min-h-screen gradient-bg relative h-full">
      <nav className="flex justify-between items-center p-4">
        <div className="flex items-center">
          <img src={icarus} alt="Icarus Logo" className="w-16 h-16" />
          <div className="font-extrabold text-md hidden md:block text-white">
            ICARUS
          </div>
        </div>
        <div className="flex space-x-1 md:space-x-4">
          <a href="https://t.me/IcarusTheory" className="text-white underline">
            Telegram
          </a>
          <a
            href="https://icarus-theory.gitbook.io/icarus-theory/"
            className="text-white underline"
          >
            Whitepaper
          </a>
          <a
            href="https://twitter.com/IcarusTheoryErc"
            className="text-white underline"
          >
            Twitter
          </a>
          <a href="https://github.com/" className="text-white underline">
            Github
          </a>
        </div>
      </nav>

      <div className="flex-grow flex-col flex items-center justify-center">
        <span className="text-white font-bold text-2xl -mb-5 md:hidden">
          ICARUS
        </span>
        <img src={icarus} className="mx-auto logo" />
        <span className="text-gray-300 font-light text-lg">
          Redefined liquidity mechanics
        </span>
        <span className="text-white font-bold text-2xl">Coming soon... </span>
      </div>
    </div>
  );
};

export default Icarus;
